<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <PageHeader title="SW900 Events" backTo="tools" />
    <main class="p-6 flex space-y-6 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <Table
          v-if="ivyEvents.length > 0"
          :columns="columns"
          :data="ivyEvents"
          :totalRecords="paginationOptions.totalRecords"
          :hasPagination="true"
          :currentPage="paginationOptions.currentPage"
          :totalPages="paginationOptions.totalPages"
          :initialSortOptions="sortOptions"
          @onSortChanged="onTableSortChanged"
          @onPageChanged="onTablePageChanged"
        />
      </div>
    </main>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Table from "@/components/Table.vue";

export default {
  name: "IvyEvents",
  components: {
    PageHeader,
    Table,
  },
  data() {
    return {
      viewState: "Idle",
      ivyEvents: [],
      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      sortOptions: {
        field: "created_at",
        isAsc: false,
        pageSize: 50,
      },
      columns: [
        {
          title: "Site Name",
          field: "site_name",
          selector: (row) => row.site_name,
        },
        {
          title: "Event Type",
          field: "event_type",
          selector: (row) => row.event_type,
        },
        {
          title: "User",
          field: "user",
          selector: (row) => row.user,
        },
        {
          title: "Date",
          field: "event_date",
          selector: (row) => row.event_date,
          type: "date",
        },
      ],
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadEvents();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    loadEvents: async function() {
      this.viewState = "Loading";
      try {
        // let result = await this.DocumentService.getDocuments({
        //   ...this.paginationOptions,
        //   ...this.sortOptions,
        //   category: this.categoryFilter,
        // });
        // console.log(result);
        // this.ivyEvents = result.data;
        // this.paginationOptions.currentPage = result.page;
        // this.paginationOptions.totalPages = result.page_count;
        // this.paginationOptions.totalRecords = result.count;
        this.ivyEvents = [
          {
            site_name: "Site Name 1",
            event_type: "Location Activated",
            user: "chrisroberts",
            event_date: 1613574623,
          },
          {
            site_name: "Site Name 2",
            event_type: "Location Activated",
            user: "bradcalcutt",
            event_date: 1613574623,
          },
          {
            site_name: "Site Name 3",
            event_type: "Location Activated",
            user: "chrisroberts",
            event_date: 1613574623,
          },
          {
            site_name: "Site Name 4",
            event_type: "Location Activated",
            user: "chrisroberts",
            event_date: 1613574623,
          },
        ];
        this.paginationOptions.currentPage = 1;
        this.paginationOptions.totalPages = 1;
        this.paginationOptions.totalRecords = this.ivyEvents.length;
      } catch (error) {
        console.error(error);
      } finally {
        this.viewState = "Idle";
      }
    },
    onTableSortChanged: async function(sortOptions) {
      console.log(
        "[IvyEvents] onTableSortChanged: Changed Sort: ",
        sortOptions
      );
      this.sortOptions = sortOptions;
      await this.loadEvents();
    },
    onTablePageChanged: async function(direction) {
      console.log(
        "[IvyEvents] onTablePageChanged: Page changed in the following direction: ",
        direction
      );
      this.paginationOptions.currentPage =
        this.paginationOptions.currentPage + direction;
      await this.loadEvents();
    },
  },
};
</script>
